html {
  scroll-behavior: smooth;
  background-color: var(--white-200);
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

input::file-selector-button {
  background-color: var(--blue-200);
  color: var(--white);
  padding: 0.8em 1rem;
  height: 3rem;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin-bottom: 2px;

  &:hover {
    background-color: var(--blue-400);
  }
}
input:disabled::file-selector-button {
  background: #DFE4E8;
  color: #9A9A9A ;
  cursor: not-allowed;
}


:root {
  --blue-100: #00a3fc;
  --blue-200: #00aff2;
  --blue-400: #0487ba;
  --blue-900: #001e50;

  --white: #ffffff;
  --white-200: #f2f2f2;

  --gray-100: #e1e1e6;
  --gray-200: #c9c9ce;
  --gray-300: #a8a8b3;
  --gray-700: #303036;
  --gray-900: #121214;

  --red-100: #ff0000;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: "100%";
  height: 36.5px;
  cursor: pointer;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
label[data-shrink="false"] + .MuiInputBase-formControl {
  color: #fff;
}

// img {
//   text-indent: -10000px;
// }
